const DEPOSIT = 'deposit';
const WIRE = 'wire';
const CC_WIRE = 'cc-wire';
const CREDIT_CARD = 'cc';
const UP_FRONT = '75-later';
const FULL = 'total-now';
const STRIPE = 'stripe';
const WALLETS = 'wallets';
export const PAYMENT_OPTIONS = {
    CC_WIRE
};
export const PAYMENT_METHODS = {
    CREDIT_CARD,
    WIRE,
    DEPOSIT,
    STRIPE,
    WALLETS
};
export const PAYMENT_MODALITIES = {
    UP_FRONT,
    FULL
};
export const PRE_AUTHORIZATION_PAYMENT_STATUS = {
    REQUIRES_ACTION: 'requires_action'
};
export const ENABLED_CURRENCIES = ['ARS', 'EUR', 'USD'];
export const DEFAULT_DEPOSIT_PERCENTAGE = 25;
export const acceptedCurrencies = [
    { id: 1, title: 'ARS' },
    { id: 2, title: 'CAD' },
    { id: 3, title: 'CHF' },
    { id: 4, title: 'EUR' },
    { id: 5, title: 'GBP' },
    { id: 6, title: 'ISK' },
    { id: 7, title: 'JPY' },
    { id: 8, title: 'NOK' },
    { id: 9, title: 'NZD' },
    { id: 10, title: 'PLN' },
    { id: 11, title: 'SEK' },
    { id: 12, title: 'USD' }
];
