import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { usePayment } from '../../contexts/PaymentContext';
import { useI18n } from '../../contexts/i18nContext';
import { useBooking } from '../../contexts/BookingContext';
import CreditCard from './CreditCard';
import BankTransfer from './BankTransfer';
import OtherMethods from './OtherMethods';
import formatMoney from '../../lib/formatMoney';
import AppleAndGooglePay from './AppleAndGooglePay';
import PromotedMethod from './PromotedMethod';
export default function PaymentMethods() {
    const { booking } = useBooking();
    const { t } = useI18n();
    const { isUpfrontPayment, depositWithCard, loadingPrices, isFullPayment, firstDepartureInLessThan7Days } = usePayment();
    if (!booking)
        return null;
    return (_jsxs("div", { className: "flex flex-col gap-1 mt-10", children: [_jsx("h2", { className: "text-2xl font-bold text-black", children: t(isUpfrontPayment ? 'choosePaymentMethodForRemaining' : 'choosePaymentMethod') }), isUpfrontPayment ? (loadingPrices ? (_jsx("div", { className: "h-3 mt-1 bg-gray-200 rounded-full dark:bg-gray-200 w-3/4" })) : (_jsx("div", { className: "font-extralight text-sm", dangerouslySetInnerHTML: {
                    __html: t('whetherToPay', {
                        price: `${booking.leadCurrency} ${formatMoney(depositWithCard.remaining.subtotal, booking.leadCurrency)}`
                    })
                } }))) : null, _jsx(CreditCard, {}), !firstDepartureInLessThan7Days ? _jsx(BankTransfer, {}) : null, _jsx(PromotedMethod, {}), _jsx(OtherMethods, {}), isFullPayment ? _jsx(AppleAndGooglePay, {}) : null] }));
}
