import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import Input from '../../tailwind/Input';
import { formatCreditCard, formatExpiry, getCreditCardBrand, getMaxCardLength } from '../../../lib/payments/cc-form-utils';
import AmExIcon from '../../Icons/AmExIcon';
import Discover from '../../Icons/Discover';
import MasterCardIcon from '../../Icons/MasterCardIcon';
import VisaIcon from '../../Icons/VisaIcon';
import UnknownCard from '../../Icons/UnknownCard';
import { useBooking } from '../../../contexts/BookingContext';
import { useI18n } from '../../../contexts/i18nContext';
import { usePayment } from '../../../contexts/PaymentContext';
import formatMoney from '../../../lib/formatMoney';
import DinersClub from '../../Icons/DinersClub';
const cardIcon = {
    amex: AmExIcon,
    discover: Discover,
    mastercard: MasterCardIcon,
    visa: VisaIcon,
    dinersclub: DinersClub,
    unknown: UnknownCard
};
const CreditCardForm = ({ children }) => {
    const { booking } = useBooking();
    const { t } = useI18n();
    const { depositWithCard, isUpfrontPayment } = usePayment();
    const [cvc, setCvc] = useState('');
    const [expiry, setExpiry] = useState('');
    const [name, setName] = useState('');
    const [cardNumber, setCardNumber] = useState('');
    const CardIcon = cardIcon[getCreditCardBrand(cardNumber)];
    const fields = [
        {
            label: t('cardNumber'),
            placeholder: '4444 4444 4444 4444',
            fn: (value) => {
                const cardLength = getMaxCardLength(value);
                if (value.length > cardLength)
                    return;
                setCardNumber(value);
            },
            value: formatCreditCard(cardNumber),
            name: 'number',
            icon: _jsx(CardIcon, {}),
            autoFocus: true
        },
        {
            label: t('expirationDate'),
            placeholder: 'MM/YY',
            fn: (value) => {
                if (value.length > 5)
                    return;
                setExpiry(value);
            },
            name: 'expiry',
            value: formatExpiry(expiry)
        },
        {
            label: t('nameOnCard'),
            placeholder: 'John Doe',
            fn: setName,
            name: 'name',
            value: name
        },
        {
            label: 'CVC',
            placeholder: '412',
            fn: (value) => {
                if (!/^[0-9]+$/g.test(value) && value !== '')
                    return;
                if (value.length > 4)
                    return;
                setCvc(value);
            },
            name: 'cvc',
            value: cvc
        }
    ];
    const isValidForm = Boolean(cvc.length >= 3
        && expiry.match(/^(0[1-9]|1[0-2])\/?([0-9]{2})$/)
        && name
        && cardNumber);
    const paymentData = {
        cvv: cvc,
        expiry,
        name,
        number: cardNumber
    };
    return (_jsxs("div", { className: "my-5", children: [fields.map(({ label, placeholder, fn, name, value, icon, autoFocus }) => (_jsxs("label", { children: [_jsx("span", { className: "text-sm font-normal text-gray-900 mt-2", children: label }), _jsx("div", { className: "relative", children: _jsx(Input, { name: name, placeholder: placeholder, onChange: fn, errorLabels: "Error", isValid: true, required: true, value: value, endDecorator: icon, autoFocus: autoFocus }, name) })] }, name))), isUpfrontPayment && booking && booking.guide_state !== 'G:CONFIRMED' ? (_jsxs("div", { className: "font-extralight p-4 my-4 bg-[#f3f3f3]", children: [t('youWillPreAuthorize'), ' ', _jsxs("strong", { children: [' ', booking.leadCurrency, " ", formatMoney(depositWithCard.deposit.amount, booking.leadCurrency)] }), ". ", t('wontBeChargedYet')] })) : null, children(isValidForm, { paymentData })] }));
};
export default CreditCardForm;
