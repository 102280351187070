import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import UserProvider from './contexts/UserContext';
import I18nContextProvider from './contexts/i18nContext';
import MainPage from './pages/Main';
import { BookingProvider } from './contexts/BookingContext';
import PaymentContextProvider from './contexts/PaymentContext';
import { Toaster } from 'react-hot-toast';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ThankYouPage from './pages/ThankYou';
import RatesProvider from './contexts/RatesContext';
import Debug from './components/Debug';
import InstallmentPage from './pages/InstallmentPage';
import Error404 from './pages/Error/404';
import InstallmentContextProvider from './contexts/InstallmentContext';
function App() {
    return (_jsxs(Routes, { children: [_jsx(Route, { path: "/thank-you", element: _jsx(ThankYouPage, {}) }), _jsx(Route, { path: "/", element: _jsx(MainPage, {}) }), _jsx(Route, { path: "/installment", element: _jsx(InstallmentPage, {}) }), _jsx(Route, { path: "*", element: _jsx(Error404, {}) }), _jsx(Route, { path: "/404", element: _jsx(Error404, {}) })] }));
}
function ProvidersWithApp() {
    return (_jsx(BrowserRouter, { children: _jsx(RatesProvider, { children: _jsx(UserProvider, { children: _jsx(BookingProvider, { children: _jsx(I18nContextProvider, { children: _jsx(PaymentContextProvider, { children: _jsxs(InstallmentContextProvider, { children: [_jsx(Toaster, {}), _jsx(App, {}), _jsx(Debug, {})] }) }) }) }) }) }) }));
}
export default ProvidersWithApp;
