import sha256 from '../../sha256';
import fetcher from '../../../api-requests/fetcher';
export async function purchaseDataLayer({ booking, revenue, }) {
    if (!booking?.payment_plan || !window.dataLayer)
        return;
    window.dataLayer.push({ ecommerce: null });
    const data = {
        event: 'purchase',
        ecommerce: {
            match_keys: {
                email: [await sha256(booking.lead.email)],
                phone: booking.lead.phone ? [await sha256(booking.lead.phone)] : [],
                lead_id: booking.lead._id,
                booking_id: booking._id,
            },
            transaction_id: booking._id,
            value: booking.payment_plan.computedCalculations.total,
            currency: booking.payment_plan.original.displayCurrency,
            items: [
                {
                    item_name: booking.trip.i18n?.en_EN?.title || booking.trip.title,
                    item_id: booking.trip._id,
                    price: booking.payment_plan.computedCalculations.pricePerPersonTotal,
                    quantity: booking.payment_plan.original.quantity,
                    location_id: booking.trip.locations?.[0]._id ||
                        booking.trip.regions?.[0]._id ||
                        booking.trip.countries?.[0]._id,
                    revenue,
                },
            ],
        },
    };
    window.dataLayer.push(data);
    fetcher({
        url: '/api/v3/facebook/SSPixel/purchase',
        data: { ...data, cookies: document.cookie },
        method: 'POST',
    });
}
export async function beginCheckoutDataLayer({ booking, prices, }) {
    if (!booking || !window.dataLayer)
        return;
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
        event: 'begin_checkout',
        ecommerce: {
            match_keys: {
                email: [await sha256(booking.lead.email)],
                phone: booking.lead.phone ? [await sha256(booking.lead.phone)] : [],
                lead_id: booking.lead._id,
                booking_id: booking._id,
            },
            phone: booking.lead.phone ? [await sha256(booking.lead.phone)] : [],
            email: [await sha256(booking.lead.email)],
            value: prices.generics.total,
            currency: prices.generics.currency,
            items: [
                {
                    item_name: booking.trip.i18n.en_EN.title,
                    item_id: booking.trip._id,
                    price: prices.generics.pricePerPersonTotal,
                    quantity: prices.generics.quantity,
                },
            ],
        },
    });
}
export function sendPurchaseToFb({ booking }) {
    // if (!window.fbq || !booking?.payment_plan) return
    // window.fbq('track', 'Purchase', {
    //   content_type: 'hotel',
    //   content_ids: booking.trip._id,
    //   currency: booking.payment_plan.original.displayCurrency,
    //   value: booking.payment_plan.computedCalculations.total
    // })
}
