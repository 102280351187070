import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { usePayment } from '../../contexts/PaymentContext';
import CheckBox from './CheckBox';
import { PAYMENT_MODALITIES } from '../../lib/payments/constant';
import { useI18n } from '../../contexts/i18nContext';
import { useBooking } from '../../contexts/BookingContext';
import formatMoney from '../../lib/formatMoney';
import Lock from '../Icons/Lock';
import Clock from '../Icons/Clock';
export default function PaymentModalities() {
    const { booking } = useBooking();
    const { t } = useI18n();
    const { setPaymentModality, isFullPayment, isUpfrontPayment, firstDepartureInLessThan45Days, depositWithCard, loadingPrices, basePriceWithCustomerFee } = usePayment();
    if (!booking)
        return null;
    return (_jsx(_Fragment, { children: _jsxs("div", { className: "flex flex-col my-7", children: [_jsx("h2", { className: "text-2xl text-black font-bold", children: t('howToPay') }), _jsxs("div", { className: "flex justify-center gap-2 text-[#6a9d25] my-7 flex-col", children: [_jsxs("div", { className: "flex gap-2", children: [_jsx(Lock, {}), " ", t('paymentPoweredByStripe')] }), _jsxs("div", { className: "flex gap-2", children: [_jsx(Clock, {}), " ", t('freeCancellation')] })] }), _jsxs("div", { className: "gap-1 flex flex-col", children: [_jsx(CheckBox, { label: t('payInFull'), isActive: isFullPayment, setPaymentModality: () => setPaymentModality(PAYMENT_MODALITIES.FULL), children: loadingPrices ? (_jsx("div", { className: "h-3 mt-1 bg-gray-200 rounded-full dark:bg-gray-200 w-3/4" })) : (_jsxs(_Fragment, { children: [_jsxs("span", { className: "font-bold", children: [booking.leadCurrency, ' ', formatMoney(basePriceWithCustomerFee, booking.leadCurrency)] }), ' ', t('now')] })) }), firstDepartureInLessThan45Days ? null : (_jsx(CheckBox, { label: t('payLessUpfront'), isActive: isUpfrontPayment, setPaymentModality: () => setPaymentModality(PAYMENT_MODALITIES.UP_FRONT), children: loadingPrices ? (_jsx("div", { className: "h-3 mt-1 bg-gray-200 rounded-full dark:bg-gray-200 w-3/4" })) : (_jsxs(_Fragment, { children: [_jsxs("span", { className: "font-bold", children: [booking.leadCurrency, ' ', formatMoney(depositWithCard.deposit.amount, booking.leadCurrency)] }), ' ', t('ccNowRestLater')] })) }))] })] }) }));
}
