import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useBooking } from '../../../contexts/BookingContext';
import { useI18n } from '../../../contexts/i18nContext';
import { usePayment } from '../../../contexts/PaymentContext';
import useRedirectToStripe from '../../../hooks/useRedirectToStripe';
import formatMoney from '../../../lib/formatMoney';
import { PAYMENT_METHODS } from '../../../lib/payments/constant';
import Klarna from '../../Icons/Klarna';
import CheckBox from '../CheckBox';
import Amplitude from '../../../lib/Amplitude';
export default function PromotedMethod() {
    const { t } = useI18n();
    const { booking } = useBooking();
    const { stripePromotedMethodUrl, payWithPromotedMethod } = useRedirectToStripe();
    const [showPromotedMethod, setShowPromotedMethod] = useState(false);
    const { setPaymentMethod, basePriceWithCustomerFee, isFullPayment, isStripe, loadingPrices } = usePayment();
    useEffect(() => {
        Amplitude.getVariant('202407-promote-payment-method-klarna').then(variant => {
            setShowPromotedMethod(variant === 'show-promoted-payment-method');
        });
    }, []);
    if (!booking || !isFullPayment)
        return null;
    return stripePromotedMethodUrl && showPromotedMethod ? (_jsx(CheckBox, { isActive: isStripe, setPaymentModality: () => {
            if (isStripe)
                return setPaymentMethod(null);
            payWithPromotedMethod();
            return setPaymentMethod(PAYMENT_METHODS.STRIPE);
        }, label: _jsxs("div", { className: "w-full flex items-center gap-1", children: ["Klarna ", _jsx(Klarna, {}), loadingPrices ? (_jsx("div", { className: "h-3 mt-1 bg-gray-200 rounded-full dark:bg-gray-200 w-1/6" })) : (_jsxs("span", { className: "text-sm font-extralight", children: ["(+", booking.leadCurrency, " 0.00)"] }))] }), children: _jsx("div", { children: _jsxs("div", { className: "flex items-center gap-1", children: [t('youWillPayATotal'), ' ', loadingPrices ? (_jsxs(_Fragment, { children: [_jsx("div", { className: "h-3 mt-1 bg-gray-200 rounded-full dark:bg-gray-200 w-1/6" }), "."] })) : (_jsxs("strong", { children: [booking.leadCurrency, " ", formatMoney(basePriceWithCustomerFee, booking.leadCurrency)] })), ' '] }) }) })) : null;
}
