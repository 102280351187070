import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useBooking } from '../../../contexts/BookingContext';
import { useI18n } from '../../../contexts/i18nContext';
import { usePayment } from '../../../contexts/PaymentContext';
import { PAYMENT_METHODS } from '../../../lib/payments/constant';
import AmExIcon from '../../Icons/AmExIcon';
import MasterCardIcon from '../../Icons/MasterCardIcon';
import VisaIcon from '../../Icons/VisaIcon';
import CheckBox from '../CheckBox';
import CreditCardForm from './CreditCardForm';
import FinishBooking from '../FinishBooking';
import { createRef, useEffect } from 'react';
import formatMoney from '../../../lib/formatMoney';
import Resume from '../../Resume/Resume';
export default function CreditCard() {
    const ccRef = createRef();
    const { booking } = useBooking();
    const { t } = useI18n();
    const { setPaymentMethod, paymentMethod, paymentModality, isUpfrontPayment, isCreditCard, fullCreditCard, depositWithCard, loadingPrices } = usePayment();
    const { remaining } = depositWithCard;
    const { ccFee, amount } = remaining;
    useEffect(() => {
        if (isCreditCard && ccRef.current) {
            ccRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [isCreditCard, ccRef]);
    if (!booking)
        return null;
    return (_jsxs("div", { ref: ccRef, children: [_jsxs(CheckBox, { label: _jsxs("div", { className: 'w-full flex items-center gap-1', children: [t('creditCard'), '', loadingPrices ? (_jsx("div", { className: "h-3 mt-1 bg-gray-200 rounded-full dark:bg-gray-200 w-1/6" })) : (_jsxs("span", { className: "text-sm font-extralight flex items-center", children: ["(+", booking.leadCurrency, ' ', formatMoney(isUpfrontPayment ? ccFee : fullCreditCard.ccFee, booking.leadCurrency), ")"] }))] }), isActive: isCreditCard, setPaymentModality: () => {
                    if (paymentMethod === PAYMENT_METHODS.CREDIT_CARD)
                        return setPaymentMethod(null);
                    return setPaymentMethod(PAYMENT_METHODS.CREDIT_CARD);
                }, children: [isUpfrontPayment ? (_jsxs("div", { children: [loadingPrices ? (_jsx("div", { className: "h-3 mt-1 bg-gray-200 rounded-full dark:bg-gray-200 w-1/6" })) : (_jsxs("span", { className: 'font-bold', children: [booking.leadCurrency, " ", formatMoney(amount, booking.leadCurrency)] })), ' ', t('charged45DaysBeforeDP')] })) : (_jsxs("div", { className: 'flex items-center gap-1', children: [t('youWillPayATotal'), ' ', loadingPrices ? (_jsx("div", { className: "h-3 mt-1 bg-gray-200 rounded-full dark:bg-gray-200 w-1/6" })) : (_jsxs("strong", { children: [booking.leadCurrency, " ", formatMoney(fullCreditCard.amount, booking.leadCurrency)] }))] })), _jsxs("div", { className: "flex gap-3 mt-2", children: [_jsx(AmExIcon, {}), _jsx(MasterCardIcon, {}), _jsx(VisaIcon, {})] })] }), isCreditCard && Boolean(paymentModality) ? (_jsx(CreditCardForm, { children: (isValidForm, { paymentData }) => (_jsx(FinishBooking, { isValidForm: isValidForm, paymentData: paymentData, children: _jsx(Resume, {}) })) })) : null] }));
}
