import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { loadStripe } from '@stripe/stripe-js';
import PaymentMethods from './PaymentMethods';
import PaymentModalities from './PaymentModalities';
import { usePayment } from '../../contexts/PaymentContext';
import { Elements } from '@stripe/react-stripe-js';
import { useBooking } from '../../contexts/BookingContext';
import { useI18n } from '../../contexts/i18nContext';
import { ISO_LOCALE } from '../../constants/locale';
const stripePromise = loadStripe(process.env.STRIPE_KEY);
export default function Payments() {
    const { t, currentLocale } = useI18n();
    const { paymentModality } = usePayment();
    const { booking } = useBooking();
    const paymentModalityChosen = Boolean(paymentModality);
    const i18nLocale = ISO_LOCALE[currentLocale];
    const linkTrip = booking?.trip.i18n[i18nLocale]?.link;
    return (_jsxs(Elements, { stripe: stripePromise, children: [_jsx(PaymentModalities, {}), linkTrip && (_jsx("div", { className: "text-sm  mt-3 md:p-0 pb-4 px-4 text-center md:hidden", children: _jsx("a", { href: `${linkTrip}more-info`, target: "_blank", className: "text-main", children: t('askQuestions') }) })), paymentModalityChosen && _jsx(PaymentMethods, {})] }));
}
