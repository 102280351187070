import fetcher from './fetcher';
export function getBookingById(bookingId) {
    return fetcher({
        url: `/api/v1/status/bookings/${bookingId}`,
        method: 'GET'
    });
}
export function persistPriceCalculation({ paymentMethod, bookingId, options }) {
    return fetcher({
        url: `/api/v1/status/bookings/${bookingId}/price-calculation`,
        method: 'PATCH',
        data: {
            paymentMethod,
            options
        }
    });
}
