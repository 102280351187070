import { capturePaymentIntent, confirmPaymentIntent, createPaymentIntent, preAuthorize } from '../../../api-requests/payments';
import { PRE_AUTHORIZATION_PAYMENT_STATUS } from '../constant';
import { handleCardAction } from '../stripe';
async function handlePreAuthorize({ bookingId, preAuthorizationData, stripe, installmentId }) {
    const installment_id = new URLSearchParams(window.location.search).get('installmentId') || installmentId;
    const body = installment_id ? { ...preAuthorizationData, installment_id } : preAuthorizationData;
    try {
        const { paymentData: requiresActionData } = await preAuthorize(bookingId, body);
        if (requiresActionData.status === PRE_AUTHORIZATION_PAYMENT_STATUS.REQUIRES_ACTION) {
            await handleCardAction(bookingId, requiresActionData, stripe);
        }
    }
    catch (error) {
        const err = error;
        const declineCode = err.response?.data?.message;
        return Promise.reject(declineCode || 'paymentFailed');
    }
}
export default async function handlePay({ paymentData, bookingId, stripe, country, installmentId }) {
    const [expiryMonth, expiryYear] = paymentData.expiry.split('/');
    const currentCentury = new Date().getFullYear().toString().slice(0, 2);
    const billingCountry = country ||
        (await (async () => {
            try {
                const geo = await fetch('https://geolocation-db.com/json/', {
                    method: 'GET'
                });
                const geoData = await geo.json();
                return geoData.country_name;
            }
            catch (err) {
                return 'United States';
            }
        })());
    const preAuthorizationData = {
        card_number: paymentData.number,
        card_holder: paymentData.name,
        card_expiry_month: parseInt(expiryMonth),
        card_expiry_year: parseInt(`${currentCentury}${expiryYear}`),
        card_cvv: paymentData.cvv,
        billing_country: billingCountry
    };
    return await handlePreAuthorize({
        bookingId,
        preAuthorizationData,
        stripe,
        installmentId
    });
}
export async function handleWalletPayment({ stripe, ev, booking }) {
    const { clientSecret, paymentIntentId } = await createPaymentIntent(booking._id, ev.walletName);
    const { error: confirmError, paymentIntent } = await stripe.confirmCardPayment(clientSecret, { payment_method: ev.paymentMethod.id }, { handleActions: false });
    await confirmPaymentIntent(booking._id, paymentIntentId, ev.walletName, {
        error: confirmError
    });
    if (confirmError) {
        return ev.complete('fail');
    }
    if (booking.guide_state === 'G:CONFIRMED') {
        await capturePaymentIntent(booking._id, paymentIntent.id);
    }
    ev.complete('success');
}
