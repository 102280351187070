import { useStripe } from '@stripe/react-stripe-js';
import { useEffect, useState } from 'react';
import { useBooking } from '../contexts/BookingContext';
import { usePayment } from '../contexts/PaymentContext';
import usePostPayment from './usePostPayment';
import { ISO_LOCALE } from '../constants/locale';
import { useI18n } from '../contexts/i18nContext';
export default function usePaymentRequest() {
    const stripe = useStripe();
    const { booking } = useBooking();
    const { currentLocale } = useI18n();
    const { fullCreditCard } = usePayment();
    const [canMakePayment, setCanMakePayment] = useState(false);
    const [paymentRequest, setPaymentRequest] = useState(null);
    const [walletName, setWalletName] = useState(null);
    const { postWalletPayment } = usePostPayment();
    useEffect(() => {
        if (!stripe || !booking)
            return;
        const stripePaymentRequest = stripe.paymentRequest({
            country: 'US',
            currency: booking.leadCurrency.toLowerCase(),
            total: {
                label: booking.trip.i18n[ISO_LOCALE[currentLocale]]?.title || booking.trip.title || '',
                amount: Number(fullCreditCard.amount.toFixed(booking.leadCurrency === 'JPY' ? 0 : 2).replace('.', '')),
            },
        });
        stripePaymentRequest.canMakePayment().then(result => {
            const resultWithType = result;
            if (resultWithType?.applePay) {
                setWalletName('applePay');
            }
            if (resultWithType?.googlePay) {
                setWalletName('googlePay');
            }
            if (resultWithType?.link && !resultWithType?.applePay && !resultWithType?.googlePay) {
                return setCanMakePayment(false);
            }
            return setCanMakePayment(!!result);
        });
        stripePaymentRequest.on('paymentmethod', async (ev) => {
            return await postWalletPayment(ev);
        });
        setPaymentRequest(stripePaymentRequest);
    }, [stripe, booking]);
    return { canMakePayment, paymentRequest, walletName };
}
