import fetcher from '../api-requests/fetcher';
export const DEFAULT_DEPOSIT = 25;
export const DEFAULT_AFTER_DEPOSIT = 75;
export const CONVERSION_FEE = 1.02;
export function getConversionFee(booking) {
    if (booking.leadCurrency === booking.currency)
        return 1;
    return CONVERSION_FEE;
}
export function getTotalBasedOnModalityAndMethod({ isCreditCard, isFullPayment, isUpfrontPayment, isStripe, isWallet, fullPriceNow, basePriceWithCustomerFee, firstPaymentAmount, ccFeeForRestAfterDeposit }) {
    if (isFullPayment) {
        if (isCreditCard || isWallet)
            return fullPriceNow;
        return basePriceWithCustomerFee;
    }
    if (isUpfrontPayment) {
        if (isCreditCard)
            return fullPriceNow;
        return firstPaymentAmount + ccFeeForRestAfterDeposit;
    }
    return basePriceWithCustomerFee;
}
export function getRevenue(bookingId) {
    return fetcher({
        url: `/api/v1/dashboard/bookings/revenue/${bookingId}`,
        method: 'GET'
    });
}
